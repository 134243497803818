import Head from 'next/head'

const defaults = {
  title: 'Cypress Tricks & Tips',
  description:
    'Cypress tips and tricks by Gleb Bahmutov, links to videos, blog posts, courses',
  image: 'https://cypress.tips/cypress-tips.png',
}

export default function PageHead({ title, description, image }) {
  title = title || defaults.title
  description = description || defaults.description
  image = image || defaults.image

  return (
    <Head>
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta
        name="description"
        property="og:description"
        content={description}
        key="ogdesc"
      />
      <meta name="author" content="Gleb Bahmutov" />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" key="twcard" />
      <meta name="twitter:creator" content="@bahmutov" key="twhandle" />
      <meta name="twitter:title" content={title} key="twtitle" />
      <meta name="twitter:description" content={description} key="twdesc" />
      <meta name="twitter:image" content={image} key="twimage" />
      {/* Open Graph */}
      <meta name="title" property="og:title" content={title} key="ogtitle" />
      <meta name="og:type" property="og:type" content="website" key="ogtype" />
      <meta
        property="og:site_name"
        content="Cypress Tips & Tricks"
        key="ogsitename"
      />
      <meta name="og:image" property="og:image" content={image} key="ogimage" />
    </Head>
  )
}
