import { useEffect, useState } from 'react'

/**
 * @see https://stackoverflow.com/questions/58293542/next-js-warning-expected-server-html-to-contain-a-matching-a-in-div-how-to
 */
const ClientOnly = ({ children, ...delegated }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    setHasMounted(true)
  }, [])

  if (!hasMounted) {
    return null
  }

  return <div {...delegated}>{children}</div>
}
export default ClientOnly
