import React, { useEffect, useState } from 'react'
import styles from './DarkMode.module.css'

const DarkModeToggle = ({ forceUpdate }) => {
  const [theme, setTheme] = useState(() => {
    const storedTheme = localStorage.getItem('theme')

    const prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    const defaultDark =
      storedTheme === 'dark' || (storedTheme === null && prefersDark)
    return defaultDark ? 'dark' : 'light'
  })

  useEffect(() => {
    localStorage.setItem('theme', theme)
    document.documentElement.setAttribute('data-theme', theme)
  }, [theme])

  const toggleTheme = (e) => {
    if (e.target.checked) {
      setTheme('dark')
    } else {
      setTheme('light')
    }
    setTimeout(() => {
      forceUpdate?.()
    }, 0)
  }

  const label = theme === 'dark' ? 'Activate light mode' : 'Activate dark mode'

  return (
    <div className={styles['toggle-theme-wrapper']} data-cy="DarkMode">
      <span>☀️</span>
      <label
        className={styles['toggle-theme']}
        htmlFor="checkbox"
        title={label}
      >
        <input
          type="checkbox"
          id="checkbox"
          aria-label={label}
          onChange={toggleTheme}
          defaultChecked={theme === 'dark'}
        />
        <div className={`${styles.slider} ${styles.round}`}></div>
      </label>
      <span>🌒</span>
    </div>
  )
}

export default DarkModeToggle
