import React from 'react'
import ClientOnly from './ClientOnly'
import DarkModeToggle from './DarkModeToggle'

// wrap the actual toggle in a component
// that only renders it if running in the browser
const DarkMode = ({ forceUpdate }) => {
  return (
    <ClientOnly>
      <DarkModeToggle forceUpdate={forceUpdate} />
    </ClientOnly>
  )
}

export default DarkMode
