import PageHead from '@/src/page-head'
import styles from '../styles/Home.module.css'
import DarkMode from '@/components/DarkMode'

export default function Home() {
  return (
    <div className={styles.container}>
      <PageHead
        title="Cypress Tricks & Tips"
        description="Cypress tips and tricks by Gleb Bahmutov, links to videos, blog posts, courses"
        image="https://cypress.tips/cypress-tips.png"
      />

      <div className={styles.topBar}>
        <DarkMode />
      </div>

      <main className={styles.main}>
        <h1 className={styles.title} data-cy="title">
          Cypress Tips & Tricks
        </h1>

        <p className={styles.description}>
          by{' '}
          <a href="https://glebbahmutov.com" target="_blank">
            Gleb Bahmutov, PhD
          </a>
        </p>

        <h2 id="new-resource" className={styles.newResourceTitle}>
          Check out my{' '}
          <a href="/courses" target="_self">
            Cypress.io courses
          </a>{' '}
          🎓
        </h2>
        {/* <div className={styles.newResource}>
          <a
            href="/courses/network-testing"
            target="_self"
            className={styles.newCourseTitle}
          >
            Cypress Network Testing Exercises
          </a>
        </div>
        <div className={styles.newResource}>
          <a
            href="/courses/cypress-plugins"
            target="_self"
            className={styles.newCourseTitle}
          >
            Cypress Plugins
          </a>
        </div> */}

        {/* <ul className={styles.newResources}>
          <li>
            <a
              title="new video"
              href="https://www.youtube.com/watch?v=6-xHHtAzNtk"
            >
              📹 cypress-recurse: pagination example{' '}
            </a>{' '}
            <small>16 March 2021</small>
          </li>
          <li>
            <a
              title="new blog post"
              href="https://filiphric.com/improve-your-custom-command-logs-in-cypress"
            >
              📝 Improve your custom command logs in Cypress{' '}
            </a>{' '}
            <small>by Filip Hric, 15 March 2021</small>
          </li>
          <li>
            <a
              title="new video"
              href="https://www.youtube.com/watch?v=CU8C6MRP_GU"
            >
              📹 Wait for the API to respond using cypress-recurse
            </a>{' '}
            <small>15 March 2021</small>
          </li>
          <li>
            <a
              title="new blog post"
              href="https://www.cypress.io/blog/2021/03/01/generate-high-resolution-videos-and-screenshots/"
            >
              📝 Generate High-Resolution Videos and Screenshots
            </a>{' '}
            <small>1 March 2021</small>
          </li>
          <li>
            <a
              title="new recipe"
              href="https://github.com/cypress-io/cypress-example-recipes/tree/master/examples/fundamentals__dynamic-tests-from-csv"
            >
              📬 Dynamic tests from CSV data
            </a>{' '}
            <small>15 March 2021</small>
          </li>
          <li>
            <a
              title="new recipe"
              href="https://github.com/cypress-io/cypress-example-recipes/pull/675"
            >
              📬 Pass value between specs
            </a>{' '}
            <small>15 March 2021</small>
          </li>
        </ul> */}

        {/* <div id="spotlight">
          <strong>Spotlight 👏</strong>{' '}
          <a
            title="great blog post"
            href="https://javascript.plainenglish.io/sharpen-your-testing-skills-by-debugging-in-cypress-597de915ffab"
          >
            Sharpen Your Testing Skills by Debugging in Cypress
          </a>{' '}
          teaches you how to effectively debug Cypress test code. <br />
          <small style={{ float: 'right' }}>
            by Jonathan Thompson, 16 March 2021
          </small>
        </div> */}

        <div className={styles.grid}>
          {/* <a
            href="/courses"
            className={styles.card}
            title="My Cypress test exercise course"
          >
            <h3>🙇 Online Courses &rarr;</h3>
            <p>Learn Cypress with my hands-on lessons</p>
          </a> */}

          <a
            href="/courses"
            className={styles.card}
            title="Cypress courses by Gleb Bahmutov"
          >
            <h3>🎓 Courses &rarr;</h3>
            <p>My Cypress online courses</p>
          </a>

          <a
            href="https://cypresstips.substack.com"
            className={styles.card}
            title="Email newsletter bringing a new tip every day in December 2021"
          >
            <h3>📆 Advent Calendar &rarr;</h3>
            <p>Tips & tricks in your inbox</p>
          </a>

          <a
            href="https://glebbahmutov.com/blog/tags/cypress/"
            className={styles.card}
            title="The best in-depth blog posts about Cypress"
          >
            <h3>📚 Blog posts &rarr;</h3>
            <p>300+ blog posts about Cypress</p>
          </a>

          <a
            href="https://www.youtube.com/playlist?list=PLP9o9QNnQuAYYRpJzDNWpeuOVTwxmIxcI"
            className={styles.card}
          >
            <h3>📺 Watch &rarr;</h3>
            <p>500+ videos about Cypress</p>
          </a>

          <a href="/search" className={styles.card} aria-label="Search">
            <h3>🔎 Search &rarr;</h3>
            <p>Find answer to any Cypress question</p>
          </a>

          <a href="/skills" className={styles.card}>
            <h3>✅ Cypress Skills &rarr;</h3>
            <p>Evaluate your knowledge</p>
          </a>

          <a
            href="/workshops"
            className={styles.card}
            title="List of Cypress workshop I teach"
          >
            <h3>💻 Workshops &rarr;</h3>
            <p>Learn from the best</p>
          </a>

          {/* <a href="/vote" className={styles.card}>
            <h3>🗳 Vote &rarr;</h3>
            <p>On the next blog post topic</p>
          </a> */}

          <a
            href="https://glebbahmutov.com/cypress-examples"
            className={styles.card}
            title="Hundreds examples of individual Cypress commands"
          >
            <h3>📖 Reference &rarr;</h3>
            <p>Cypress command examples</p>
          </a>

          <a
            href="https://slides.com/bahmutov/decks/cypress-io"
            className={styles.card}
            title="Slides from my presentations about Cypress"
          >
            <h3>📽 Presentations &rarr;</h3>
            <p>Slides from presentations</p>
          </a>

          <a href="https://www.cypress.io/blog/" className={styles.card}>
            <h3>📰 Cypress blog &rarr;</h3>
            <p>The official company blog</p>
          </a>

          <a
            href="https://www.youtube.com/watch?v=-3N4WZRrpv4&list=PL8GlT7H3xOcKHZlXjWjWUQl2fnedXuT14"
            className={styles.card}
          >
            <h3>🖥 Webinars &rarr;</h3>
            <p>Cypress Webinars</p>
          </a>

          <a
            href="/which-cypress-command-are-you"
            className={styles.card}
            title="It is fun to know your spiritual command"
          >
            <h3>⁉️ Command Quiz &rarr;</h3>
            <p>Which Cypress Command Are You?</p>
          </a>

          <a href="https://www.cypress.io/events" className={styles.card}>
            <h3>🎤 Events &rarr;</h3>
            <p>Cypress Events</p>
          </a>
        </div>
      </main>

      <footer className={styles.footer}>
        Note: Cypress.Tips is not endorsed by or a part of the official
        Cypress.io
      </footer>
    </div>
  )
}
